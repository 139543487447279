import { useFormikContext } from "formik";
import { IUseProduto } from "../../../Hooks/Produto";
import { EProdutoTipo, IProduto } from "../../../Models/cardapio";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    Produto: IUseProduto;
    Categoria: IUseCardapio;
    Confirmado: boolean;
};

const CardapioVinculoIntegracao = (props: IProvider) => {
    // const { Produto } = props;
    const { values, handleChange } = useFormikContext<IProduto>();

    return (
        <Accordion className="mt-3">
            <Accordion.Item eventKey="1" onClick={() => {
                setTimeout(() => {
                    document.getElementById('fldVinculoProduto')?.scrollIntoView()
                }, 500);
            }}>
                <Accordion.Header>
                    Vincular Produto
                </Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-3">
                        <Col xs={7}> 
                            <Form.Group>
                                <Form.Label>Código do produto da Integração:</Form.Label>
                                <Form.Control
                                    name={'PRODUTO_INTEGRACAO.CD_PRODUTO_TERCEIRO'}
                                    type={'text'}
                                    value={values.PRODUTO_INTEGRACAO?.CD_PRODUTO_TERCEIRO ? values.PRODUTO_INTEGRACAO?.CD_PRODUTO_TERCEIRO[0] : []}
                                    onChange={handleChange}
                                    disabled={Number(values.PRODUTO_INTEGRACAO) === undefined}
                                    size="lg"
                                />
                            </Form.Group>  
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default CardapioVinculoIntegracao;