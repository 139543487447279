import { useCallback } from "react";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import useApp from "../utils/useApp";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IEntrega, IEntregaItem } from "../../Models/cadEntrega";
import { IUseVinculoProdutoController } from "./controller";

const useVinculoProduto = (Controller: IUseVinculoProdutoController): IUseVinculoProduto => {
    const { Modal } = useApp();

    const EnvIntegracaoTerceiro = useCallback((Dados: DeepPartial<IEntrega>) => {
        const enviar = (Values: ICtxAppParams_ModalInputs) => {
            // Validação: verificar se algum campo está vazio ou inválido
            const hasEmptyFields = Values.some(value => {
                const val = value.Value;
                return val === undefined || val === null || val === "" || (typeof val === "number" && isNaN(val));
            });

            if (hasEmptyFields) {
                Modal({
                    Visibilidade: true,
                    Anexos: undefined,
                    Titulo: 'Erro de Validação',
                    Descricao: 'Por favor, preencha todos os campos antes de confirmar.',
                    Variante: 'alert',
                    Inputs: [],
                    onSubmit: () => {}, // Nenhuma ação ao confirmar o erro
                });
                return; // Interrompe o envio
            }

            if (Array.isArray(Dados.ENTREGA_ITENS)) {
                Dados.ENTREGA_ITENS.map((item: IEntregaItem, index: number) => {
                    if (Array.isArray(Dados.ENTREGA_ITENS)) {
                        Dados.ENTREGA_ITENS[index].CD_PRODUTO_TERCEIRO = Values[index].Value;
                    }
                });
                Controller.Cadastrar.mutate(Dados);
            }
        };

        Modal({
            Visibilidade: true,
            Titulo: 'Vincular produto',
            Descricao: `Insira nos campos vazios o código do produto do seu sistema fiscal.`,
            Anexos: undefined,
            Inputs: 
                Array.isArray(Dados.ENTREGA_ITENS)
                    ? Dados.ENTREGA_ITENS.reduce((acc: any[], item: IEntregaItem, index: number) => {
                        acc.push({
                            ID: `ENTREGA_ITEM_${index}`,
                            Label: String(item.PRODUTO_NOME ?? 'Produto não pôde ser identificado.'),
                            Placeholder: '',
                            Type: 'text',
                            Value: String(item.PRODUTO_INTEGRACAO.CD_PRODUTO_TERCEIRO ?? ''),
                        });

                        return acc;
                    }, [])
                    : [],
            Variante: 'success',
            onSubmit: enviar,
        });
    }, [Modal, Controller]);

    return {
        EnvIntegracaoTerceiro,
    };
};

export interface IUseVinculoProduto {
    EnvIntegracaoTerceiro: (Dados: DeepPartial<IEntrega>) => void;
}

export default useVinculoProduto;
