import { useContext, useEffect, useState } from "react";
import { Button, Container, InputGroup, Form, DropdownButton, Dropdown, Row, Col, ButtonGroup } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { HiRefresh } from "react-icons/hi"
import Tabela from "../../Components/tabela";
import { IUseCadEntregaLista } from "../../Hooks/cadEntrega/lista";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
import { EStatus, ETpFiltro, IEntrega } from "../../Models/cadEntrega";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useMask from "../../Hooks/utils/useMask";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import LoadingIcon from "../../Components/misc/LoadingIcons/LoadingIcon";
import { ContextMenu } from "../../Components/misc/ContextMenu/ContextMenu";
import { FaCheck, FaCheckSquare, FaInfoCircle, FaPaperPlane, FaThumbsDown, FaTimes } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { MdDeliveryDining } from "react-icons/md";
import useApp from "../../Hooks/utils/useApp";
import { Gi3DStairs } from "react-icons/gi";
import { IVendaPagamentos } from "../../Models/VendaPagamento";
import { FaPrint } from "react-icons/fa6";
import { IoFastFoodOutline } from "react-icons/io5";
import useVinculo, { IUseVinculo } from "../../Hooks/vinculoProduto/vinculoProduto";

const CadEntregaLista = () => {
    const { dtCompetencia } = useParams();
    const [contexto, setContexto] = useState<IEntrega | undefined>();
    const [modalContexto, setModalContexto] = useState<string>('');
    const EntregaLista = useOutletContext<IUseCadEntregaLista>();   
    const UserParams = useContext(CtxParams);
    const permiteCadastro = UserParams.DT_COMPETENCIA !== "0" && !UserParams.ENTREGADOR && String(UserParams.DT_COMPETENCIA) === (dtCompetencia?.toString() || '');
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();
    const formatter = useMask();
    const navigate = useNavigate();    
    const vinculo = useVinculo();    

    useEffect(() => {
        AppParams.setPagina(`Entregas: ${formatter.setDate(new Date(dtCompetencia!), {day: "2-digit", month: "short", year: "numeric"})}`);    

        if (dtCompetencia === '0') {
            navigate(`/`)
        };
    }, [dtCompetencia, AppParams, navigate, formatter]);

    return (
        <>
            <ModalConfirmacao
                titulo="Finalizar entregas pendentes"
                show={modalContexto === 'finalizarLote'} 
                onHide={() => setModalContexto('')}
                variant={"danger"}
                onSubmitHandler={() => {
                    EntregaLista.FinalizarLote.mutate();
                    setModalContexto('');
                }}
            >
                Tem certeza que deseja finalizar todas as entregas pendentes do dia? <br />
                <br />
                Só serão finalizadas aquelas que tiverem um entregador associado e ainda estiver pendente. O pagamento também será confirmado. <br/>
                <div className="w-100 mt-3 d-flex justify-content-center">
                    A AÇÃO NÃO PODERÁ SER DESFEITA.
                </div>
            </ModalConfirmacao>

            <ModalConfirmacao
                titulo="Buscando dados"
                show={EntregaLista.fazendoIntegracao} 
                variant={"none"}
            >
                <div className="w-100 my-3 d-flex flex-wrap align-items-center justify-content-center">
                    <div className="w-100 d-flex justify-content-center">
                        <LoadingIcon />
                    </div>
                    <div className="w-100 mt-3 d-flex justify-content-center">Aguarde, isso pode demorar um pouco...</div>
                </div>
            </ModalConfirmacao>

            <Container className="verticalCenter">
                <div className="d-flex w-100 mb-3 justify-content-around flex-wrap mx-3">
                    <div className="w-100">
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Pagamento:</Form.Label>
                                    <Form.Select 
                                        name={'FPagos'}
                                        value={AppParams.Filtros.Entrega.filtroPagamento}
                                        onChange={(e: any) => Filtrar('Entrega', {filtroPagamento: e.target.value})}
                                        placeholder="" 
                                        size="lg"
                                    > 
                                        <option key={'T'} value={'T'}>Todos</option>
                                        <option key={'S'} value={'S'}>Pagos</option>
                                        <option key={'N'} value={'N'}>Não Pagos</option>
                                    </Form.Select>
                                </Form.Group>  
                            </Col>

                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Status:</Form.Label>
                                    <Form.Select 
                                        name={'STATUS'}
                                        value={AppParams.Filtros.Entrega.filtroStatus}
                                        onChange={(e: any) => Filtrar('Entrega', {filtroStatus: e.target.value})}
                                        placeholder="" 
                                        size="lg"
                                    > 
                                        <option key={'T'} value={'T'}>Todos</option>
                                        <option key={'P'} value={'P'}>Pendente</option>
                                        <option key={'A'} value={'A'}>Andamento</option>
                                        <option key={'F'} value={'F'}>Finalizada</option>
                                        <option key={'C'} value={'C'}>Cancelada</option>
                                    </Form.Select>
                                </Form.Group>  
                            </Col>
                        </Row>
                    </div>

                    <div className="w-100 mt-3">
                        <InputGroup>
                            <DropdownButton
                            style={{backgroundColor: '#4CAF50'}}
                                variant="outline-secondary"
                                title={
                                    (() => {
                                        switch (AppParams.Filtros.Entrega.tipoPesquisa) {
                                        case ETpFiltro.Cliente:
                                            return 'Cliente'
                                        case ETpFiltro.Entregador:
                                            return 'Entregador'
                                        case ETpFiltro.Endereco:
                                            return 'Endereço'
                                        case ETpFiltro.Pagamento:
                                            return 'Pagamento'
                                        case ETpFiltro.Pedido:
                                            return 'Nr Pedido'
                                        case ETpFiltro.Senha:
                                            return 'Nr Senha'
                                        default:
                                            return 'erro'
                                        }
                                    })()
                                }
                                id="TPFiltro"
                                
                            >
                                <Dropdown.Item key={'1'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Cliente})}>Cliente</Dropdown.Item>
                                <Dropdown.Item key={'2'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Entregador})}>Entregador</Dropdown.Item>
                                <Dropdown.Item key={'3'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Endereco})}>Endereço</Dropdown.Item>
                                <Dropdown.Item key={'4'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Pagamento})}>Pagamento</Dropdown.Item>
                                <Dropdown.Item key={'5'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Pedido})}>Nr Pedido</Dropdown.Item>
                                <Dropdown.Item key={'6'} onClick={() => Filtrar('Entrega', {tipoPesquisa: ETpFiltro.Senha})}>Nr Senha</Dropdown.Item>
                            </DropdownButton>

                            <Form.Control
                                placeholder="Filtro..."
                                aria-label="Filtro"
                                size="lg"
                                value={AppParams.Filtros.Entrega.pesquisa}
                                onChange={(e : any) => Filtrar('Entrega', {pesquisa: e.target.value})}
                            />
                        </InputGroup>
                    </div>
                    
                    
                </div>

                <ContextMenu
                    context={contexto}
                    header={(ctx: IEntrega) => process.env.REACT_APP_DEMOMODE === 'S'
                        ? (ctx.CLIENTE.NOME_PEDIDO ?? ctx.CLIENTE.NOME).split(" ")[0]
                        : (ctx.CLIENTE.NOME_PEDIDO ?? ctx.CLIENTE.NOME)
                    }
                    items={(ctx: IEntrega) => [
                        {
                            name: 'altStatus',
                            icon: <FaInfoCircle style={{zoom: '1.1'}} />,
                            caption: 'Alterar Status',
                            enabled: ctx.STATUS === EStatus.Finalizada,
                            children: [
                                {
                                    name: 'altStatusPendente',
                                    icon: <BiTimeFive style={{zoom: '1.1'}} />,
                                    caption: 'Pendente',
                                    action: () => EntregaLista.Controller.Alterar.mutate({CD_ENTREGA: ctx.CD_ENTREGA, STATUS: EStatus.Pendente})
                                },
                                {
                                    name: 'altStatusPreparacao',
                                    icon: <IoFastFoodOutline style={{zoom: '1.1'}} />,
                                    caption: 'Preparação',
                                    action: () => EntregaLista.Controller.Alterar.mutate({CD_ENTREGA: ctx.CD_ENTREGA, STATUS: EStatus.Preparacao})
                                },
                                {
                                    name: 'altStatusAndamento',
                                    icon: <MdDeliveryDining style={{zoom: '1.2'}} />,
                                    caption: 'Andamento',
                                    action: () => EntregaLista.Controller.Alterar.mutate({CD_ENTREGA: ctx.CD_ENTREGA, STATUS: EStatus.Andamento})
                                },
                                {
                                    name: 'altStatusFinalizado',
                                    icon: <FaCheck />,
                                    caption: 'Finalizado',
                                    action: () => EntregaLista.Controller.Alterar.mutate({CD_ENTREGA: ctx.CD_ENTREGA, STATUS: EStatus.Finalizada})
                                },
                                {
                                    name: 'altStatusCancelado',
                                    icon: <FaTimes />,
                                    caption: 'Cancelado',
                                    action: () => EntregaLista.Controller.Deletar.mutate({CD_ENTREGA: ctx.CD_ENTREGA, STATUS: EStatus.Cancelada, CODIGO_CANCELAMENTO: 509, RAZAO_CANCELAMENTO: 'DIFICULDADES INTERNAS DO RESTAURANTE'})
                                },
                            ]
                        },
                        {
                            name: 'imprimirEntrega',
                            icon: <FaPrint />,
                            disabled: !(ctx.ENTREGA_ITENS && ctx.ENTREGA_ITENS?.length > 0) || UserParams.ENTREGADOR,
                            caption: 'Imprimir',
                            action: () => EntregaLista.Report.Print('ENTREGA', ctx)
                        },
                        {
                            name: 'envIntegracaoTerceiro',
                            icon: <FaPaperPlane style={{zoom: "1.2", color: "black"}} />,
                            disabled: UserParams.ENTREGADOR,
                            caption: 'Enviar para integração',
                            action: () => vinculo.Modal.EnvIntegracaoTerceiro(ctx)

                        },
                        {
                            name: 'altVlrAdicional',
                            icon: <Gi3DStairs style={{zoom: "1.2", color: "black"}} />,
                            disabled: UserParams.ENTREGADOR,
                            caption: 'Adicional Entregador...',
                            action: () => EntregaLista.Modal.ValorAdicional(ctx)
                        },
                        ctx.PAGO ?
                            {
                                name: 'desPagamento',
                                icon: <FaThumbsDown style={{color: "goldenrod"}} />,
                                caption: 'Desfazer Pagamento',
                                action: () => EntregaLista.VendaPagamento.Modal.Deletar({CD_PAGTO: (ctx.PAGTO as IVendaPagamentos)[0].CD_PAGTO})
                            }
                        :
                            {
                                name: 'conPagamento',
                                icon: <FaCheckSquare style={{zoom: '1.1', color: 'green'}} />,
                                caption: 'Confirmar Pagamento',
                                action: () => EntregaLista.VendaPagamento.Modal.Pagamento({
                                    CD_ENTREGA: ctx.CD_ENTREGA!, 
                                    OFF_TOTAL: Number(ctx.VLR_PEDIDO ?? 0), 
                                    OFF_FORMA: ctx.FORMA_PAGTO,
                                    OFF_TROCO: ctx.VLR_TROCO
                                })
                            }
                    ]}
                />
                
                <Tabela 
                    colunas={EntregaLista.Grid} 
                    dados={EntregaLista.Lista} 
                    handleClick={EntregaLista.VerEntrega} 
                    estadoInicial={EntregaLista.EstadoInicial} 
                    handleContext={setContexto}
                />

                {/* Gestor - Dia em Aberto */}
                <FormRodape style={permiteCadastro ? {} : {display: 'none'}}>
                    <Button variant="success" size="lg" onClick={EntregaLista.Cadastro.Abrir}><TiPlus /> Cadastrar</Button>
                    <Dropdown as={ButtonGroup}>
                        <Button variant="dark" size="lg" onClick={EntregaLista.SincronizarEntrega} disabled={AppParams.BloqueiaSincronizacao || EntregaLista.fazendoIntegracao}><HiRefresh className={AppParams.BloqueiaSincronizacao || EntregaLista.fazendoIntegracao ? "rotacao" : ""} /> Sincronizar</Button>
                        <Dropdown.Toggle split variant="dark" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setModalContexto('finalizarLote')}>Finalizar todas entregas pendentes</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </FormRodape>

                {/* Gestor - Dia Fechado */}
                <FormRodape style={!permiteCadastro ? {} : {display: 'none'}}>
                    <Button variant="outline-primary" size="lg" onClick={() => navigate("/fechamento")}>Voltar</Button>
                </FormRodape>

                <FormRodape style={UserParams.ENTREGADOR ? {display: 'block'} : {display: 'none'}}>
                    <Row>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                Diária:
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                {formatter.setMaskMoney(EntregaLista.ListaTotal[0].TxDiaria, "R$")}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                Taxas:
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                {formatter.setMaskMoney(EntregaLista.ListaTotal[0].TxReal, "R$")} / {formatter.setMaskMoney(EntregaLista.ListaTotal[0].TxPotencial, "R$")}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                Valor Total:
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="w-100 d-flex justify-content-center">
                                {formatter.setMaskMoney(EntregaLista.ListaTotal[0].Total, "R$")}
                            </div>
                        </Col>
                    </Row>
                </FormRodape>
            </Container>
        </>
    );
};

export default CadEntregaLista;