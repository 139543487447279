import useVinculoProdutoController from "./controller";
import useVinculoProduto, { IUseVinculoProduto } from "./vinculoModal";

const useVinculo = () : IUseVinculo => {

    const Controller = useVinculoProdutoController('vinculoProduto', true);
    const Modal = useVinculoProduto(Controller);
    return {
        Modal: Modal
    }
}

export interface IUseVinculo {
    Modal: IUseVinculoProduto
}

export default useVinculo;