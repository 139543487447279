import { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { every } from "lodash";
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import FormRodape from "../../Components/formularios/FormRodape";
import { useOutletContext } from "react-router-dom";
import { EStatus, ICadEntrega, schemaCadEntrega } from "../../Models/cadEntrega";
import FormAutocomplete from "../../Components/formularios/FormAutocomplete";
import CadEntregaProvider from "./indexProvider";
import useMask from "../../Hooks/utils/useMask";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { BiTimeFive } from "react-icons/bi";
import { MdDeliveryDining } from "react-icons/md";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IUseCadEntregaLista } from "../../Hooks/cadEntrega/lista";

const CadEntrega = () => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const Entrega = useOutletContext<IUseCadEntregaLista>();//useCadEntrega(typeof cdEntrega === 'undefined' ? '-1' : cdEntrega?.toString());
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Cadastro de Entrega");
    });

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaCadEntrega}
                onSubmit={
                    
                    (e : ICadEntrega) => {
                        console.log(e);
                        Entrega.Cadastro.Confirmar(e);
                    }
                }
                initialValues={{
                    NOME_ENTREGA        : Entrega.Cadastro.dsEntrega.NOME_ENTREGA ?? '',
                    ENDERECO_CLIENTE    : '',//(`${CadastroEntrega.dsEntrega.ENDERECO.ENDERECO_BAIRRO}, ${CadastroEntrega.dsEntrega.ENDERECO.ENDERECO}, ${CadastroEntrega.dsEntrega.ENDERECO.ENDERECO_NUMERO}`),
                    NOME_USUARIO        : '',
                    CD_CLIENTE_ENDERECO : Entrega.Cadastro.dsEntrega.CD_CLIENTE_ENDERECO || null,
                    CD_CLIENTE          : Entrega.Cadastro.dsEntrega.CD_CLIENTE          || null,
                    CD_USUARIO          : Entrega.Cadastro.dsEntrega.CD_USUARIO          || null,
                    TAXA                : Entrega.Cadastro.dsEntrega.TAXA                || 0,
                    VLR_PEDIDO          : Entrega.Cadastro.dsEntrega.VLR_PEDIDO          || 0,
                    VLR_TROCO           : Entrega.Cadastro.dsEntrega.VLR_TROCO           || 0,
                    FORMA_PAGTO         : Entrega.Cadastro.dsEntrega.FORMA_PAGTO         || "A vista",
                    STATUS              : Entrega.Cadastro.dsEntrega.STATUS              || EStatus.Pendente,
                    PAGO                : Entrega.Cadastro.dsEntrega.PAGO                || false,
                    OBSERVACOES         : Entrega.Cadastro.dsEntrega.OBSERVACOES         || "",
                    VLR_TOTAL           : (Number(Entrega.Cadastro.dsEntrega.VLR_PEDIDO) + Number(Entrega.Cadastro.dsEntrega.VLR_TROCO)) || 0,
                    EXPORTADA           : Entrega.Cadastro.dsEntrega.EXPORTADA           || false
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit}>      
                        <CadEntregaProvider customHook={Entrega.Cadastro}>
                            <Row>
                                <Col>
                                    <Form.Group className="mt-3" style={{display: 'none'}}>
                                        <Form.Label>Cliente:</Form.Label>
                                        <Form.Control 
                                            name={'CD_CLIENTE'}
                                            type={'number'}
                                            readOnly
                                            value={values.CD_CLIENTE ?? ''}
                                            onChange={ (e : any) => setFieldValue("CD_CLIENTE", e.target.value) }
                                            isInvalid={every([errors.CD_CLIENTE, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                        />
                                    </Form.Group>  
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Cliente: </Form.Label>
                                        <BadgeValidacao validacao={errors.CD_CLIENTE} confirmado={confirmado} />
                                        <FormAutocomplete 
                                            name={'NOME_CLIENTE'}
                                            type={'text'}
                                            value={values.NOME_ENTREGA ?? ''}
                                            onChange={(e: any) => {
                                                setFieldValue("NOME_ENTREGA", e);
                                            }}
                                            isInvalid={every([errors.CD_CLIENTE, confirmado])}
                                            dataSource={Entrega.Cadastro.dsListaClientes}
                                            indexer={'NOME'}
                                            indexerAlt={'CELULAR'}
                                            indexKey={'CD_CLIENTE'}
                                            notFoundAlt={<>Não há registros<br/><br/> <Button variant="success" onClick={() => Entrega.Cadastro.AbrirCadastroCliente()}>Cadastrar novo cliente</Button></>}
                                            valueKey={values.CD_CLIENTE ?? ''}
                                            onChangeValueKey={ (e : any) => {
                                                setFieldValue("CD_CLIENTE", e);
                                                Entrega.Cadastro.mutClienteEndereco(values.CD_CLIENTE!)
                                            }}
                                            size="lg"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mt-3" style={{display: 'none'}}>
                                        <Form.Label>Endereço:</Form.Label>
                                        <Form.Control 
                                            name={'CD_CLIENTE_ENDERECO'}
                                            type={'number'}
                                            readOnly
                                            value={values.CD_CLIENTE_ENDERECO ?? ''}
                                            onChange={ (e : any) => {setFieldValue("CD_CLIENTE_ENDERECO", e.target.value)} }
                                            isInvalid={every([errors.CD_CLIENTE_ENDERECO, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                        />
                                    </Form.Group>  
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mt-3 w-100">
                                        <Form.Label>Endereço: </Form.Label>
                                        <BadgeValidacao validacao={errors.CD_CLIENTE_ENDERECO} confirmado={confirmado} />
                                        <FormAutocomplete 
                                            autoComplete={'off'} 
                                            name={'ENDERECO_CLIENTE'}
                                            type={'text'}
                                            value={values.ENDERECO_CLIENTE ?? ''}
                                            onChange={handleChange}
                                            isInvalid={every([errors.CD_CLIENTE_ENDERECO, confirmado])}
                                            onFocusPopup={true}
                                            dataSource={Entrega.Cadastro.dsListaClientesEndereco}
                                            indexer={'ENDERECO'}
                                            indexKey={'CD_CLIENTE_ENDERECO'}
                                            notFoundAlt={
                                                ((typeof values.CD_CLIENTE !== 'undefined') && values.CD_CLIENTE !== null) ?
                                                    <>Não há endereços cadastrados para este cliente<br/><br/> <Button variant="success" onClick={() => Entrega.Cadastro.AbrirCadastroCliente(values.CD_CLIENTE)}>Cadastrar novo endereço</Button></>
                                                :
                                                    <>Não há registros</>
                                            }
                                            alternative={
                                                <Button variant="success" onClick={() => Entrega.Cadastro.AbrirCadastroCliente(values.CD_CLIENTE)}>Cadastrar novo endereço</Button>
                                            }
                                            valueKey={values.CD_CLIENTE_ENDERECO ?? ''}
                                            onChangeValueKey={ (e : any) => {setFieldValue("CD_CLIENTE_ENDERECO", e)} }
                                            size="lg"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mt-3" style={{display: 'none'}}>
                                        <Form.Label>Entregador:</Form.Label>
                                        <Form.Control 
                                            name={'CD_USUARIO'}
                                            type={'number'}
                                            readOnly
                                            value={values.CD_USUARIO ?? ''}
                                            onChange={handleChange}
                                            isInvalid={every([errors.CD_USUARIO, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                        />
                                    </Form.Group>  
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Entregador: </Form.Label>
                                        <BadgeValidacao validacao={errors.CD_USUARIO} confirmado={confirmado} />
                                        <FormAutocomplete 
                                            name={'NOME_USUARIO'}
                                            type={'text'}
                                            value={values.NOME_USUARIO ?? ''}
                                            onChange={handleChange}
                                            isInvalid={every([errors.CD_USUARIO, confirmado])}
                                            onFocusPopup={true}
                                            dataSource={Entrega.dsEntregadores}
                                            indexer={'NOME'}
                                            indexKey={'CD_USUARIO'}
                                            valueKey={values.CD_USUARIO ?? ''}
                                            onChangeValueKey={ (e : any) => setFieldValue("CD_USUARIO", e) }
                                            size="lg"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Taxa:</Form.Label>
                                        <BadgeValidacao validacao={errors.TAXA} confirmado={confirmado} />
                                        <InputGroup>
                                            <InputGroup.Text>R$</InputGroup.Text>
                                            <Form.Control 
                                                name={'TAXA'}
                                                type={'number'}
                                                min={0}
                                                step={0.01}
                                                value={values.TAXA ?? 0}
                                                onChange={handleChange}
                                                onBlur={() => setFieldValue("TAXA", formatter.setMaskMoney(values.TAXA ?? 0, "", "."))}
                                                onWheel={(e: any) => e.target.blur()}
                                                isInvalid={every([errors.TAXA, confirmado])}
                                                placeholder="" 
                                                size="lg"
                                            />
                                        </InputGroup>
                                    </Form.Group>  
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label className="d-flex align-items-center">
                                            Status: {(() => {
                                                switch (values.STATUS) {
                                                    case 'P':
                                                        return <BiTimeFive />
                                                    case 'A':
                                                        return <MdDeliveryDining />
                                                    case 'F':
                                                        return <FaCheck />
                                                    case 'C':
                                                        return <FaTimes />
                                                }
                                            })()}
                                        </Form.Label> 
                                        <BadgeValidacao validacao={errors.STATUS} confirmado={confirmado} />
                                        <Form.Select 
                                            name={'STATUS'}
                                            value={values.STATUS ?? EStatus.Pendente}
                                            onChange={handleChange}
                                            isInvalid={every([errors.STATUS, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                        > 
                                            <option key={'P'} value={'P'}>Pendente</option>
                                            <option key={'A'} value={'A'}>Andamento</option>
                                            <option key={'F'} value={'F'}>Finalizada</option>
                                            <option key={'C'} value={'C'}>Cancelada</option>
                                        </Form.Select>
                                    </Form.Group>  
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Forma de Pagamento:</Form.Label>
                                        <BadgeValidacao validacao={errors.FORMA_PAGTO} confirmado={confirmado} />
                                        <Form.Control 
                                            name={'FORMA_PAGTO'}
                                            type={'text'}
                                            value={values.FORMA_PAGTO}
                                            onChange={handleChange}
                                            isInvalid={every([errors.FORMA_PAGTO, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                        />
                                    </Form.Group>  
                                </Col>
                            </Row>

                            <Row>                                
                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Valor a Receber:</Form.Label>
                                        <BadgeValidacao validacao={errors.VLR_TOTAL} confirmado={confirmado} />
                                        <InputGroup>
                                            <InputGroup.Text>R$</InputGroup.Text>
                                            <Form.Control 
                                                name={'VLR_TOTAL'}
                                                type={'number'}
                                                min={0}
                                                step={0.01}
                                                value={values.VLR_TOTAL ?? 0}
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    setFieldValue("VLR_TOTAL", formatter.setMaskMoney(values.VLR_TOTAL ?? 0, "", "."))
                                                    if ((Number(values.VLR_PEDIDO) === Number(0)) && (Number(values.VLR_TOTAL) !== Number(0))) {
                                                        setFieldValue("VLR_PEDIDO", formatter.setMaskMoney(Number(values.VLR_TOTAL), "", "."))
                                                    }
                                                }}
                                                isInvalid={every([errors.VLR_TOTAL, confirmado])}
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="" 
                                                size="lg"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Valor do Pedido:</Form.Label>
                                        <BadgeValidacao validacao={errors.VLR_PEDIDO} confirmado={confirmado} />
                                        <InputGroup>
                                            <InputGroup.Text>R$</InputGroup.Text>
                                            <Form.Control 
                                                name={'VLR_PEDIDO'}
                                                type={'number'}
                                                min={0}
                                                step={0.01}
                                                value={values.VLR_PEDIDO ?? 0}
                                                onChange={handleChange}
                                                onBlur={() => setFieldValue("VLR_PEDIDO", formatter.setMaskMoney(values.VLR_PEDIDO ?? 0, "", "."))}
                                                onWheel={(e: any) => e.target.blur()}
                                                isInvalid={every([errors.VLR_PEDIDO, confirmado])}
                                                placeholder="" 
                                                size="lg"
                                            />
                                        </InputGroup>
                                    </Form.Group>  
                                </Col>
                            </Row>

                            <Row className="justify-content-md-center">
                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Efetuação do Pagamento:</Form.Label>
                                        <BadgeValidacao validacao={errors.PAGO} confirmado={confirmado} />
                                        <div className="w-100 d-flex align-items-center justify-content-center">
                                            <Form.Check
                                                style={{zoom: 1.5}}
                                                id="PAGO"
                                                type="switch"
                                                checked={values.PAGO}
                                                isInvalid={every([errors.PAGO, confirmado])}
                                                label={values.PAGO ? 'Pago ✅' : 'Não 👎'}
                                                // label={values.PAGO ? 'O Pedido já está pago ✅' : 'O Pedido ainda não foi pago 👎'}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Form.Group>  
                                </Col>

                                <Col xs={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Troco Calculado:</Form.Label>
                                        <BadgeValidacao validacao={errors.VLR_TROCO} confirmado={confirmado} />
                                        <InputGroup>
                                            <InputGroup.Text>R$</InputGroup.Text>
                                            <Form.Control 
                                                name={'VLR_TROCO'}
                                                type={'number'}
                                                min={0}
                                                step={0.01}
                                                value={values.VLR_TROCO ?? 0}
                                                onChange={handleChange}
                                                onBlur={() => setFieldValue("VLR_TROCO", formatter.setMaskMoney(values.VLR_TROCO ?? 0, "", "."))}
                                                isInvalid={every([errors.VLR_TROCO, confirmado])}
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="" 
                                                size="lg"
                                                disabled
                                            />
                                        </InputGroup>
                                    </Form.Group>  
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Accordion className="mt-3 mb-5">
                                        <Accordion.Item eventKey="0" onClick={() => setTimeout(() => {
                                            document.getElementById('OBSERVACOES')?.scrollIntoView()
                                        }, 500)}>
                                            <Accordion.Header>Observações</Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group >
                                                    <Form.Control
                                                        id="OBSERVACOES"
                                                        name="OBSERVACOES"
                                                        type="text"
                                                        as={"textarea"}
                                                        rows={5}
                                                        value={values.OBSERVACOES ?? ""}
                                                        onChange={handleChange}
                                                        size="lg"
                                                    />
                                                </Form.Group>  
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </CadEntregaProvider>

                        <FormRodape>
                            <Button 
                                variant="outline-danger" 
                                size="lg" 
                                type="reset"
                                onClick={() => Entrega.Cadastro.Cancelar()}
                            >
                                Cancelar
                            </Button>
                            
                            <Button 
                                variant="success" 
                                size="lg" 
                                type="submit" 
                                //disabled={editandoEndereco}
                                onClick={() => {
                                    setConfirmado(true)
                                    // console.log(errors)
                                }}
                            >
                                Confirmar
                            </Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default CadEntrega;