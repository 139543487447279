import { useCallback } from "react";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import { IUseEntregaController } from "./controller";
import useApp from "../utils/useApp";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IEntrega, IEntregaItem } from "../../Models/cadEntrega";

const useEntregaModais = (Controller: IUseEntregaController) : IUseEntregaModais => {
    const {Modal} = useApp();

    const ValorAdicional = useCallback((Dados: DeepPartial<IEntrega>) => {
        const gravar = (Values: ICtxAppParams_ModalInputs) => {
            Controller.Alterar.mutate({CD_ENTREGA: Dados.CD_ENTREGA, VLR_ADICIONAL: Number(Values[0].Value ?? 0)});
        };

        Modal({
            Visibilidade    : true,
            Titulo          : 'Valor Adicional p/ Entregador',
            Descricao       : ``,
            Anexos			: undefined,
            Inputs          : [{
                ID: 'VLR_ADICIONAL',
                Label: 'Valor Adicional:',
                Placeholder: '',
                Type: 'number',
                Value: Number(Dados.VLR_ADICIONAL ?? 0)
            }],
            Variante        : 'success',
            onSubmit        : gravar
        })
    }, [Modal, Controller.Alterar]);

    return {
        ValorAdicional: ValorAdicional
    }
}

export interface IUseEntregaModais {
    ValorAdicional: (Dados: DeepPartial<IEntrega>) => void;
}

export default useEntregaModais;