import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { ICadEntrega, ICadVinculoProduto, IEntrega, IEntregaItems, IEntregas } from "../../Models/cadEntrega";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";

const useVinculoProdutoController = (updateQueryKey?: string, isArray: boolean = true, enableQuery: boolean = false, DtCompetencia?: string) : IUseVinculoProdutoController => {
    const API = useAPI();
    const queryClient = useQueryClient();

    const primaryKey = 'CD_PRODUTO_INTEGRACAO';
    const endpoint = 'vinculoProduto';

    const updateEntrega = (oldData: any, newData: DeepPartial<IEntrega>) => {
        if (isArray) {
            return oldData.map((entrega: IEntrega) => {
                if (String(entrega.CD_ENTREGA) === String(newData.CD_ENTREGA)) {
                    return {
                        ...entrega,
                        ...newData,
                    };
                }
                return entrega;
            });
        } else {
            if (String(oldData.CD_ENTREGA) === String(newData.CD_ENTREGA)) {
                return {
                    ...oldData,
                    ...newData,
                };
            } else {
                return oldData;
            }
        }
    };

    // const qryLista = useQuery(
    //     updateQueryKey!,
    //     () => API.get(`${endpoint}/buscarTodos/`, DtCompetencia, false),
    //     {
    //         enabled: enableQuery,
    //         refetchInterval: 120000,
    //         onSuccess: () => {}
    //     }
    // );

    const mutAlterar = useMutation(
        (data : DeepPartial<IEntrega>) => {
            return API.put(`${endpoint}/alterar/${data.CD_ENTREGA}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEntrega>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IEntregas>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return updateEntrega(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutDeletar = useMutation(
        (data : DeepPartial<IEntrega>) => {
            return API.del(`${endpoint}/deletar/${data.CD_ENTREGA}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEntrega>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IEntregas>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return updateEntrega(oldData, {
                                    ...data,
                                    PAGO: false
                                });
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : { [key: string]: string | number }) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IEntrega) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<ICadVinculoProduto>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICadVinculoProduto>) => {
                // if (!!updateQueryKey) {
                //     qryLista.refetch();
                // };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    return {
        //Listar: qryLista,
        Alterar: mutAlterar,
        Buscar: mutBuscar,
        Cadastrar: mutCadastrar,
        Deletar: mutDeletar
    }
}

export interface IUseVinculoProdutoController {
    //Listar  : UseQueryResult<any, unknown>;
    Alterar : UseMutationResult<any, any, DeepPartial<IEntrega>, unknown>;
    Buscar: UseMutationResult<IEntrega, any, { [key: string]: string | number }, unknown>;
    Cadastrar : UseMutationResult<any, any, DeepPartial<ICadVinculoProduto>, unknown>;
    Deletar : UseMutationResult<any, any, DeepPartial<IEntrega>, unknown>;
}

export default useVinculoProdutoController;