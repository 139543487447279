import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { ICadEntrega, IEnderecoEntrega } from "../../Models/cadEntrega";
import useMask from "../../Hooks/utils/useMask";
import { IUseCadEntrega } from "../../Hooks/cadEntrega";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    customHook: IUseCadEntrega;
};

const CadEntregaProvider = (props: IProvider) => {
    const { values, setFieldValue } = useFormikContext<ICadEntrega>();
    const [clienteAntes, setClienteAntes] = useState<number>(0);
    const [enderecoAntes, setEnderecoAntes] = useState<any>(null);
    const formatter = useMask();

    useEffect(() => { // Relação Cliente-Endereço
        if (clienteAntes !== (values.CD_CLIENTE ?? 0)) { // Só vai executar se trocar de cliente
            props.customHook.mutClienteEndereco(values.CD_CLIENTE!); // Atualiza os dados dos endereços
            
            if (!values.CD_CLIENTE_ENDERECO && values.CD_CLIENTE) { // Se não tiver endereco preenchido e cliente, vai preencher com o default
                setFieldValue(
                    "CD_CLIENTE_ENDERECO",
                    props.customHook.dsListaClientes.filter!((dados: any) => dados.CD_CLIENTE === values.CD_CLIENTE)[0].CD_ENDERECO_PADRAO
                );
            } else if ((values.CD_CLIENTE_ENDERECO ?? 0) > 0 && !values.CD_CLIENTE) { // Se o cliente nao tiver preenchido e não tiver default, vai jogar vazio
                setFieldValue(
                    "CD_CLIENTE_ENDERECO",
                    ""
                );
            } else if (props.customHook.enderecoPadrao.ENDERECO === "" && !values.CD_CLIENTE_ENDERECO) { // Se o cliente nao tiver endereço padrão, vai jogar vazio tbm
                setFieldValue(
                    "CD_CLIENTE_ENDERECO",
                    ""
                );
            };
        
            setClienteAntes(values.CD_CLIENTE ?? 0);
        }
    }, [props.customHook, clienteAntes, values.CD_CLIENTE_ENDERECO, values.CD_CLIENTE, setFieldValue]); //eslint-disable-line

    useEffect(() => { // Atualiza endereço atual (para pegar informações dele e utilizar no hook abaixo que precisa do Bairro)
        //debugger
        const endereco = props.customHook.dsListaClientesEndereco.filter((dados: IEnderecoEntrega) => dados.CD_CLIENTE_ENDERECO === values.CD_CLIENTE_ENDERECO);
        
        if ((endereco.length > 0)) {
            props.customHook.setEnderecoAtual(endereco[0]);
        };
    }, [props.customHook.setEnderecoAtual, props.customHook.dsListaClientesEndereco, values.CD_CLIENTE_ENDERECO]) // eslint-disable-line

    useEffect(() => { // Relação endereço-taxa
        if (!values.CD_CLIENTE_ENDERECO) { // Se nao tiver endereço informado, vai jogar 0 na taxa
            setFieldValue("TAXA", formatter.setMaskMoney(0, "", "."));
        };

        if (enderecoAntes !== props.customHook.enderecoAtual.CD_CLIENTE_ENDERECO) { // Se trocar o endereço vai buscar a taxa
            const taxaAtual : any = props.customHook.dsListaTaxas.filter!((dados: any) => 
                // dados.CEP.includes(props.customHook.enderecoAtual.CEP.slice(0, 8)) ||
                // dados.CEP.includes(props.customHook.enderecoAtual.CEP.slice(0, 7)) ||
                // dados.CEP.includes(props.customHook.enderecoAtual.CEP.slice(0, 6)) ||
                // dados.CEP.includes(props.customHook.enderecoAtual.CEP.slice(0, 5))
                props.customHook.enderecoAtual.ENDERECO.toLowerCase().includes(dados.BAIRRO.toLowerCase())
            );
            setFieldValue("TAXA", formatter.setMaskMoney(taxaAtual[0]?.TAXA ?? 0, "", "."));

            setEnderecoAntes(props.customHook.enderecoAtual.CD_CLIENTE_ENDERECO);
        };
    }, [setEnderecoAntes, values.CD_CLIENTE_ENDERECO, setFieldValue, props.customHook.enderecoAtual, enderecoAntes, props.customHook.dsListaTaxas]) //eslint-disable-line

    useEffect(() => {
        setFieldValue("VLR_TROCO", formatter.setMaskMoney((Number(values.VLR_TOTAL ?? 0) - Number(values.VLR_PEDIDO ?? 0)), "", "."))
    }, [values.VLR_PEDIDO, values.VLR_TOTAL, setFieldValue]) //eslint-disable-line

    return (
        <>{props.children}</>
    )
}

export default CadEntregaProvider;